import { CompanyEntity, EntityLinksAggregation, PersonEntity } from '@deecision/dna-interfaces';
import { SimpleSort } from '@/components/sort';
import { SimpleFilter } from '@/components/filters/simple';
import { CompanyGetter } from '../../providers/getter';
import { SettingsProps } from '@/components/filters/simple/switch';

export const cardHeight = 250;

export const labelEdgeColor = {
  sha: '#1E90FF',    // DodgerBlue for Shareholder
  exe: '#FF8C00',    // DarkOrange for Executive
  both: '#9e2f9e'    // Purple for both Shareholder and Executive
};

export type DataElementChipType = {
  parent: {
    dataInfo: string | undefined,
    scheduling: string | undefined
  },
  children: {
    dataInfo: string | undefined,
    scheduling: string | undefined
  }
}

export type NodeType = {
  id: string,
  type: 'mainEntitie' | 'personEntitie' | 'companieEntitie' | 'warningShaParent',
  position: { x: number, y: number },
  nodeFrom?: string,
  nodeFromType?: string,
  hidden?: boolean,
  category?: string,
  data: {
    label: string | undefined,
    entityId?: string,
    category?: string,
    entityType?: string,
    entitie?: EntityLinksAggregation,
    entitieBasePoint?: { x: number, y: number },
    isAdvisor?: boolean,
    advisorType?: 'representative' | 'represented',
    deploy?: boolean,
    nodes?: React.MutableRefObject<{ id: string, type: string, position: { x: number, y: number }, data: { label: string | undefined } }[]>,
    filters?: React.MutableRefObject<SimpleFilter[]>,
    filtersElementView?: React.MutableRefObject<SimpleFilter[]>,
    setNodes?: React.Dispatch<React.SetStateAction<{ id: string, type: string, position: { x: number, y: number }, data: { label: string | undefined } }[]>>,
    setEdges?: React.Dispatch<React.SetStateAction<{ id: string, source: string, target: string }[]>>,
    source?: 'Right' | 'Left',
    target?: 'Right' | 'Left',
    branchNodeIds?: string[],
    filtersId?: string[],
    prxmee?: number,
    switchPrxmee? : React.MutableRefObject<boolean>,
    companieLinkedNodes?: string[],
    linkedNotes?: string[],
    parentNode?: NodeType,
    nodeNameFrom?: string,
    nodeFromType?: string,
    sharesPercentage?: number,
    labelBetweenPerson?: string[],
    isListedCompany?: boolean,
    entityGetter?: CompanyGetter,
    entityData?: (CompanyGetter | undefined)[],
    sortListRef?: React.MutableRefObject<SimpleSort[]>,
    settingsList?: React.MutableRefObject<SettingsProps[]>,
    shareholderTotalPercentage?: number,
    cardData: CompanyEntity | PersonEntity | undefined, // This is the getEntity response to display the card data
    cardLinks?: unknown // This is the getEntityLinks response to display the card links
  }
}

export type EdgeType = {
  id: string,
  source: string,
  target: string,
  type?: 'custom',
  label?: string[],
  linkTo?: 'person' | 'companie' | 'warningSha',
  data?: {
    mainEntitie: string,
    entitie: EntityLinksAggregation,
    sourceType: string,
    targetType: string,
    arrowAt: 'start' | 'end',
    filtersId?: string[],
    labelEdgeColor?: string | undefined,
    filtersElementView?: React.MutableRefObject<SimpleFilter[]>,
    settingsList?: React.MutableRefObject<SettingsProps[]>,
    highlight?: {
      color?: '#FF0000'
    }
  }
}

export type CompanieDataType = {
  data: {
    category: string,
    deploy: boolean,
    entitie: EntityLinksAggregation,
    entitieBasePoint: {x: number, y: number},
    filters: {current: SimpleFilter[]},
    filtersId: string[],
    isAdvisor: boolean,
    label: string,
    nodes: {current: NodeType[]},
    setEdges: () => void,
    setNodes: () => void,
    source: string,
    target: string
  },
  dragging: boolean,
  id: string,
  isConnectable: boolean,
  selected: boolean,
  sourcePosition: string,
  targetPosition: string,
  type: string,
  xPos: number,
  yPos: number,
  zIndex: number
};
