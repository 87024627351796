import React from 'react';
import {
  IDataBlock,
  PersonEntity,
  zExtrapolatedWeasseDataBlock,
  zIDataBlock,
  zIDataElement,
  zPersonEntity,
  zVeegilenzCompanyFactAllDataBlockContent,
  zVeegilenzPersonFactAllDataBlockContent,
  zVeegilenzPersonSummaryV1DataBlockContent,
  zVeegilenzSummaryAllDataBlockContent,
  zWeasseDataBlock
} from '@deecision/dna-interfaces';
import { IconUsers } from '@tabler/icons-react';
import { z } from 'zod';
import createElementRoutes from '@/router/create';
import { customGetterLoader } from '@/router/loader';
import dnaConfig from '../../../../config/dna.config.json';
import PersonsComponent from './index';
import PersonComponent from './components';
import BaseDnaEntitiesService from '../services/entities';
import DashboardTabsComponents from './components/tabs/dashboard';
import DeveloperTabsComponents from '@/main/modules/entities/common/tabs/developer';
import CompaniesTabsComponents from '../common/tabs/companies';
import NetworkTabsComponents from '@/main/modules/entities/common/tabs/network';
import PrioreezTabsComponents from './components/tabs/prioreez';
import OrigeenTabsComponents from '../common/tabs/origeen';
// import OrigeenTabsComponents from './custom/tabs/origeen.tabs.custom';
import RelationsTabsComponents from './components/tabs/relations';
import NewsTabsComponents, { newsDataPaths } from '@/main/modules/entities/common/tabs/news';
import { DataBlocksQueryService, DataElementsQueryService } from '@/api/services/query';
import WeasseTabsComponents, { weasseDataPaths } from './components/tabs/weasse';
import Veegilenz, { veegilenzPersonPaths } from '../../../containers/veegilenz';
import { tabsFunctions } from './components/tabs/utils/functions';
import { CustomRouteObject } from '@/router/types';

const tabsPersons: CustomRouteObject[] = [
  {
    id: dnaConfig.routes.persons.tabs.dashboard.label,
    i18nKey: dnaConfig.routes.persons.tabs.dashboard.i18nKey,
    path: dnaConfig.routes.persons.tabs.dashboard.baseUrl,
    element: <DashboardTabsComponents />
  },
  {
    id: dnaConfig.routes.persons.tabs.network.label,
    i18nKey: dnaConfig.routes.persons.tabs.network.i18nKey,
    path: dnaConfig.routes.persons.tabs.network.baseUrl,
    element: <NetworkTabsComponents<PersonEntity> />
  },
  {
    id: dnaConfig.routes.persons.tabs.companies.label,
    i18nKey: dnaConfig.routes.persons.tabs.companies.i18nKey,
    path: dnaConfig.routes.persons.tabs.companies.baseUrl,
    element: <CompaniesTabsComponents />
  },
  {
    id: dnaConfig.routes.persons.tabs.relations.label,
    i18nKey: dnaConfig.routes.persons.tabs.relations.i18nKey,
    path: dnaConfig.routes.persons.tabs.relations.baseUrl,
    element: <RelationsTabsComponents />
  },
  {
    id: dnaConfig.routes.persons.tabs.news.label,
    i18nKey: dnaConfig.routes.persons.tabs.news.i18nKey,
    path: dnaConfig.routes.persons.tabs.news.baseUrl,
    element: <NewsTabsComponents />,
    loader: ({ request, params }) => customGetterLoader({
      service: new DataBlocksQueryService<IDataBlock>({ dataPath: newsDataPaths, entityType: 'deecPerson' }),
      params,
      request
    })
  },
  {
    id: dnaConfig.routes.persons.tabs.veegilenz.label,
    i18nKey: 'Veegilenz',
    path: dnaConfig.routes.persons.tabs.veegilenz.baseUrl,
    element: <Veegilenz entityType='deecPerson' />,
    loader: ({ request, params }) => customGetterLoader({
      service: new DataBlocksQueryService<IDataBlock>({ dataPath: veegilenzPersonPaths, entityType: 'deecPerson', schema: zIDataBlock(z.union([
        zVeegilenzPersonSummaryV1DataBlockContent,
        zVeegilenzPersonFactAllDataBlockContent,
        zVeegilenzCompanyFactAllDataBlockContent,
        zVeegilenzSummaryAllDataBlockContent
      ])) }),
      params,
      request
    })
  },
  {
    id: dnaConfig.routes.persons.tabs.prioreez.label,
    i18nKey: 'Prioreez',
    path: dnaConfig.routes.persons.tabs.prioreez.baseUrl,
    element: <PrioreezTabsComponents />
  },
  {
    id: dnaConfig.routes.persons.tabs.weasse.label,
    i18nKey: dnaConfig.routes.persons.tabs.weasse.i18nKey,
    path: dnaConfig.routes.persons.tabs.weasse.baseUrl,
    element: <WeasseTabsComponents />,
    loader: ({ request, params }) => customGetterLoader({
      service: new DataBlocksQueryService<IDataBlock>({ dataPath: weasseDataPaths, entityType: 'deecPerson', schema: zIDataBlock(z.union([
        zWeasseDataBlock,
        zExtrapolatedWeasseDataBlock
      ])) }),
      params,
      request
    })
  },
  {
    id: dnaConfig.routes.persons.tabs.origeen.label,
    i18nKey: 'Origeen',
    path: dnaConfig.routes.persons.tabs.origeen.baseUrl,
    element: <OrigeenTabsComponents entityType='deecPerson' />
  },
  {
    id: dnaConfig.routes.persons.tabs.developer.label,
    i18nKey: dnaConfig.routes.persons.tabs.developer.i18nKey,
    path: dnaConfig.routes.persons.tabs.developer.baseUrl,
    element: <DeveloperTabsComponents<PersonEntity> zodSchema={zPersonEntity} />
  }
];

const routesPersons: CustomRouteObject = createElementRoutes<PersonEntity>({
  i18nKey: dnaConfig.routes.persons.i18nKey,
  path: dnaConfig.routes.persons.baseUrl,
  icon: IconUsers,
  component: <PersonsComponent />,
  idComponent: <PersonComponent />,
  Service: new BaseDnaEntitiesService<PersonEntity>({ entityType: 'person' }),
  displayIndex: true,
  tabs: tabsPersons,
  tabsDisplayService: new DataElementsQueryService({ entityType: 'deecPerson', schema: zIDataElement(z.any()) }),
  tabsFunctions
});

export default routesPersons;
