import React, { ReactElement } from 'react';
import { Button, darken } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import { BaseChartsProps } from './types';

function YesNoCharts(props: BaseChartsProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack width='100%' direction='column' spacing={2} alignItems='center'>
      <Typography variant='body2' width='100%' textAlign='center'>{props.on}</Typography>

      <Grid container spacing={2} p={2}>
        <Grid size='grow'>
          <Stack textAlign='center'>
            <Button
              variant='contained'
              onClick={() => props.setSelected?.([0])}
              sx={{
                bgcolor: darken(theme.palette.success.main, props.selected?.includes(0) ? 0.3 : 0),
                borderColor: darken(theme.palette.success.main, props.selected?.includes(0) ? 0.3 : 0),
                '&:hover': {
                  bgcolor: darken(theme.palette.success.main, 0.2),
                  borderColor: theme.palette.success.main
                }
              }}
              fullWidth
            >
              {t('common.utils.yes')}
            </Button>
            <Typography variant='h5' fontWeight={props.selected?.includes(0) ? 'bold' : 'normal'} color='primary'>
              {`${(((props.datas[0][0]?.count || 0) * 100) / ((props.datas[0][0]?.count || 0) + (props.datas[0][1]?.count || 0))).toFixed(2)}% (${props.datas[0][0]?.count || 0})`}
            </Typography>
          </Stack>
        </Grid>
        <Grid size='grow'>
          <Stack textAlign='center'>
            <Button
              variant='contained'
              onClick={() => props.setSelected?.([1])}
              sx={{
                bgcolor: darken(theme.palette.error.main, props.selected?.includes(1) ? 0.3 : 0),
                borderColor: darken(theme.palette.error.main, props.selected?.includes(1) ? 0.3 : 0),
                '&:hover': {
                  bgcolor: darken(theme.palette.error.main, 0.2),
                  borderColor: theme.palette.error.main
                }
              }}
              fullWidth
            >
              {t('common.utils.no')}
            </Button>
            <Typography  variant='h5' fontWeight={props.selected?.includes(1) ? 'bold' : 'normal'}  color='primary'>
              {`${(((props.datas[0][1]?.count || 0) * 100) / ((props.datas[0][0]?.count || 0) + (props.datas[0][1]?.count || 0))).toFixed(2)}% (${props.datas[0][1]?.count || 0})`}
            </Typography>
          </Stack>

        </Grid>
      </Grid>
    </Stack>
  );
}

export default YesNoCharts;
