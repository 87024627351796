import React, { ReactElement, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import { IconChartCircles, IconFilterSearch, IconRotate, IconUsers } from '@tabler/icons-react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { CompositeItemSpec, SegmentationData } from '@deecision/dna-interfaces';
import IconBuildings from '@/assets/custom/IconBuildings';
import ChipFilters from '@/components/filters/chip';
import { BaseSegmentationProps } from '../../types';
import valueParser from '@/utils/value-parser';

export interface FiltersSegmentationsProps extends BaseSegmentationProps {
  segmentationData?: SegmentationData,
  reset: () => void,
  listRef?: HTMLDivElement | null
}

export function FilterCriteriasSegmentations(props: Omit<BaseSegmentationProps, 'addFilter'> & { filter: BaseSegmentationProps['filters'][0], simple?: boolean }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <ChipFilters
      color='primary'
      value={
        <Stack direction='row' spacing={2} alignItems='center'>
          {!props.simple
            ? <Typography>
              {`${props.filter.label} ${props.filters.filter(f => f.id !== props.filter.id).find(f => f.filterId === props.filter.filterId) ? `(${t(
                `segmentation.builder.config.advanced.on.${props.filter.on}`
              ).split(':')[0]})` : ''}`}
            </Typography>
            : null
          }
          {props.filter.values?.map(value => (
            <Chip
              key={value?.toString() || 'null'}
              sx={{ bgcolor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
              label={valueParser({ value, valueType: props.filter.valueType, currency: 'EUR', t })} // TODO: Format Value before .toString()
              size='small'
            />
          ))}
          {props.filter.ranges?.map(range => (
            <Chip
              key={`${range.min} - ${range.max}`}
              sx={{ bgcolor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
              label={valueParser({ range, valueType: props.filter.valueType, currency: 'EUR', t })} // TODO: Format Value before displaying it
              size='small'
            />
          ))}
          {(props.filter as CompositeItemSpec).subItemSpecs &&
            <Tooltip title={(props.filter as CompositeItemSpec).subItemSpecs?.map(subItem => subItem.id).join(', ') || t('common.utils.unknown')} placement='top' arrow>
              <Chip
                sx={{ bgcolor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                label={`${((props.filter as CompositeItemSpec).subItemSpecs?.length || 0)} ${((props.filter as CompositeItemSpec).subItemSpecs?.length || 0) > 1 ? t('filters.label') : t('filters.unique')}`}
                size='small'
              />
            </Tooltip>
          }
        </Stack>
      }
      onDelete={() => props.filter.id && props.removeFilter(props.filter.id)}
      onClick={() => undefined}
    />
  );
}

function FiltersCriteriasSegmentations(props: FiltersSegmentationsProps): ReactElement {
  const { t } = useTranslation();
  const [disableReset, setDisableReset] = useState(true);

  const handleScroll = () => {
    if (props.listRef) {
      props.listRef?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    setDisableReset(false);
  }, [props.filters]);

  return (
    <Grid container spacing={4} alignItems='center'>
      <Grid>
        <Paper
          onClick={handleScroll}
          sx={{ cursor: props.listRef ? 'pointer' : 'default', width: '100%' }}
        >
          <Stack direction='row' spacing={2} p={1} alignItems='center'>
            {props.segmentationData?.objectType === 'deecPerson' ? <IconUsers size='1.6rem' /> : props.segmentationData?.objectType === 'deecCompany' ? <IconBuildings size='1.6rem' /> : <IconChartCircles />}
            <Typography variant='h4'>{props.segmentationData?.totalCount || 0}</Typography>
            <Typography variant='h4' whiteSpace='nowrap'>{`${(props.segmentationData?.totalCount || 0) > 0 ?
              t(`entities.${props.segmentationData?.objectType === 'deecPerson' ? 'person' : props.segmentationData?.objectType === 'deecCompany' ? 'company' : 'links'}.multiple`) :
              t(`entities.${props.segmentationData?.objectType === 'deecPerson' ? 'person' : props.segmentationData?.objectType === 'deecCompany' ? 'company' : 'links'}.label`)
            } ${t('segmentation.render.segmentationCriterias.matching').replace(' x ', ` ${props.filters.length.toString()} `)}`}</Typography>
          </Stack>
        </Paper>
      </Grid>
      <Grid size='grow' minWidth='min-content'>
        <Paper sx={{ width: '100%' }}>
          <Grid container spacing={2} p={props.filters.length < 1 ? 1 : 0} alignItems='center' wrap='nowrap'>
            <Grid mr={2}>
              <Box height={24}>
                <IconFilterSearch size='1.6rem' />
              </Box>
            </Grid>
            {props.filters.length > 0 ?
              [...props.filters.map(filter => (
                <Grid key={filter.id}>
                  <FilterCriteriasSegmentations {...props} filter={filter} />
                </Grid>
              )),
              <Grid>
                <Tooltip title={t('common.utils.reset')} placement='top' arrow>
                  <IconButton
                    onClick={() => {
                      setDisableReset(true);
                      props.reset();
                    }}
                    size='small'
                    disabled={disableReset}
                  >
                    <IconRotate size='1.2rem' style={{ transform: 'rotate(180deg)' }} />
                  </IconButton>
                </Tooltip>
              </Grid>] :
              <Grid>
                <Typography whiteSpace='nowrap'>
                  {t('segmentation.render.segmentationCriterias.filters.noFilters')}
                </Typography>
              </Grid>
            }
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default FiltersCriteriasSegmentations;
