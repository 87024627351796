import { SegmentationCriteria } from '@deecision/dna-interfaces';
import { Theme } from '@mui/material';
import { ValueType } from '@/utils/value-parser';

export const baseChartWidth = 480;
export const baseChartHeight = 380;

export const baseChartColors = (theme: Theme) => ([
  theme.palette.primary.main.replace(')', ', 0.75)').replace('rgb', 'rgba'),
  theme.palette.secondary.main.replace(')', ', 0.75)').replace('rgb', 'rgba'),
  theme.palette.success.main.replace(')', ', 0.75)').replace('rgb', 'rgba'),
  theme.palette.warning.main.replace(')', ', 0.75)').replace('rgb', 'rgba'),
  theme.palette.error.main.replace(')', ', 0.75)').replace('rgb', 'rgba')
]);

export function wrapEmoji(value: string, valueType?: ValueType, html?: boolean) {
  const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/;

  if (!regex.test(value)) {
    return value;
  }

  if (html) {
    return valueType === 'countryCode' || valueType === 'countryCode[]' ? `<span style="font-family: NotoColorEmojiLimited !important">${value}</span>` : value;
  }

  return valueType === 'countryCode' || valueType === 'countryCode[]' ? `{flagEmojiStyle|${value}}` : value;
}

export type DataType = Required<SegmentationCriteria>['values'][0] & {
  name?: string
};

export type FlagType = {
  code: string,
  emoji: string,
  unicode: string,
  name: string,
  title: string,
  dialCode: string
};

const veegilenzColorOrder = ['green', 'orange', 'red', 'black'];

export const colorTableForVeegilenz: { [key: string]: string } = {
  green: '#70B877',
  orange: '#F7C368',
  red: '#F0827A',
  black: '#333333'
};

export const colorTableForLevels: { [key: string]: string } = {
  level_0: '#333333',
  level_1: '#F0827A',
  level_2: '#F0827A',
  level_3: '#F7C368',
  level_4: '#70B877',
  level_5: '#70B877',
  level_6: '#70B877',
  level_7: '#004B05FF'
};

export function interpolateColors(color1: string, color2: string, steps: number): string[] {
  const hexToRgb = (hex: string) => hex.match(/\w\w/g)?.map((str: string) => parseInt(str, 16));
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  if (!rgb1 || !rgb2) {
    return (['#ffffff']);
  }

  const stepSize = [
    (rgb2[0] - rgb1[0]) / steps,
    (rgb2[1] - rgb1[1]) / steps,
    (rgb2[2] - rgb1[2]) / steps
  ];

  return Array.from({ length: steps }, (_, i) => {
    const r = Math.round(rgb1[0] + stepSize[0] * i);
    const g = Math.round(rgb1[1] + stepSize[1] * i);
    const b = Math.round(rgb1[2] + stepSize[2] * i);

    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  });
}

export const sortVeegilenzColor = (a: DataType, b: DataType) => {
  const indexA = veegilenzColorOrder.indexOf(a.value);
  const indexB = veegilenzColorOrder.indexOf(b.value);

  if (indexA === -1 && indexB === -1) {
    return 0;
  }
  if (indexA === -1) {
    return 1;
  }
  if (indexB === -1) {
    return -1;
  }

  return indexA - indexB;
};
