import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { IconArrowRight, IconChevronDown, IconChevronUp, IconClipboardData, IconX } from '@tabler/icons-react';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import { useKeycloak } from '@react-keycloak/web';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { List, ListItemButton, Tooltip } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ListItemIcon from '@mui/material/ListItemIcon';
import Chip from '@mui/material/Chip';
import getSegmentations from '../../getters/segmentation';
import { CustomSegmentationData, CustomUserData, PotentialSegmentationCriteria } from '../../types';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: 0
};

function SegmentationItem(props: { segmentation: CustomUserData<CustomSegmentationData>, onClick: () => void }): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ListItemButton onClick={props.onClick}>
      <ListItemIcon sx={{ minWidth: 24, pl: 4, pr: 4 }}>
        <IconClipboardData />
      </ListItemIcon>
      <ListItemText primary={<Typography variant='h4'>{props.segmentation.title}</Typography>} />
      <Stack direction='row' spacing={2} alignItems='center' pl={4}>
        <Chip
          label={`${props.segmentation.data.potentialSegmentationCriterias.length} ${t('segmentation.render.segmentationCriterias.label')}`}
          color='primary'
          size='small'
        />
        <Tooltip title={t('common.dates.creationDate')} arrow placement='top'>
          <Chip
            label={props.segmentation?.date ? `${new Date(props.segmentation.date).toLocaleDateString()} ${new Date(props.segmentation.date).toLocaleTimeString()}` : ''}
            color='success'
            size='small'
          />
        </Tooltip>
        <Tooltip title={t('common.dates.lastUpdate')} arrow placement='top'>
          <Chip
            label={props.segmentation?.lastUpdate ? `${new Date(props.segmentation.lastUpdate).toLocaleDateString()} ${new Date(props.segmentation.lastUpdate).toLocaleTimeString()}` : ''}
            sx={{ bgcolor: theme.palette.secondary.light, color: theme.palette.secondary.dark }}
            size='small'
          />
        </Tooltip>
      </Stack>
      <ListItemIcon sx={{ minWidth: 24, pl: 6, pr: 2 }}>
        <IconArrowRight size='1.6rem' />
      </ListItemIcon>
    </ListItemButton>
  );
}

function InheritedCriterias(props: { setCriterias: (criterias: PotentialSegmentationCriteria[]) => void }): ReactElement {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const keycloak = useKeycloak();
  const [segmentations, setSegmentations] = useState<CustomUserData<CustomSegmentationData>[]>([]);
  const [openAssistedSegmentations, setOpenAssistedSegmentations] = useState<boolean>(true);
  const [openPersonalSegmentations, setOpenPersonalSegmentations] = useState<boolean>(true);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateList = useCallback(() => {
    if (keycloak.keycloak.tokenParsed) {
      getSegmentations(keycloak.keycloak)
        .then(segmentation => setSegmentations(segmentation));
    }
  }, [keycloak.keycloak]);

  const applyCriterias = (segmentation: CustomUserData<CustomSegmentationData>) => {
    handleClose();
    props.setCriterias(segmentation.data.potentialSegmentationCriterias);
  };

  useEffect(() => {
    updateList();
  }, [keycloak.keycloak.tokenParsed]);

  return (
    <>
      <Button
        id='apply-segmentation-menu-button'
        onClick={handleClick}
        startIcon={<IconClipboardData size='1.6rem' />}
      >
        {t('segmentation.render.segmentationCriterias.segmentationSelection.label')}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='apply-segmentation-modal-title'
        aria-describedby='apply-segmentation-modal-description'
      >
        <Paper sx={style}>
          <Stack spacing={2}>
            <Stack spacing={4} direction='row' alignItems='center' justifyContent='space-between' p={2}>
              <Typography variant='h3'>
                {t('segmentation.render.segmentationCriterias.segmentationSelection.desc')}
              </Typography>
              <IconButton onClick={handleClose} size='small'>
                <IconX size='1.4rem' />
              </IconButton>
            </Stack>
            <List>
              <ListItemButton onClick={() => setOpenAssistedSegmentations(prevState => !prevState)}>
                <ListItemText primary={t('segmentation.assisted.multiFullLabel')} />
                {openAssistedSegmentations ? <IconChevronUp /> : <IconChevronDown />}
              </ListItemButton>
              <Collapse in={openAssistedSegmentations} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  {segmentations.filter(seg => seg.ownerType === 'group').map((segmentation: CustomUserData<CustomSegmentationData>) => (
                    <SegmentationItem key={segmentation._id} segmentation={segmentation} onClick={() => applyCriterias(segmentation)} />
                  ))}
                </List>
              </Collapse>
              <ListItemButton onClick={() => setOpenPersonalSegmentations(prevState => !prevState)}>
                <ListItemText primary={t('segmentation.augmented.multiCustomLabel')} />
                {openPersonalSegmentations ? <IconChevronUp /> : <IconChevronDown />}
              </ListItemButton>
              <Collapse in={openPersonalSegmentations} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  {segmentations.filter(seg => seg.ownerType === 'user').map((segmentation: CustomUserData<CustomSegmentationData>) => (
                    <SegmentationItem key={segmentation._id} segmentation={segmentation} onClick={() => applyCriterias(segmentation)} />
                  ))}
                </List>
              </Collapse>
            </List>
          </Stack>
        </Paper>
      </Modal>
    </>
  );
}

export default InheritedCriterias;
