import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import {
  ENTITY_LINK_TYPE_HAS_REPRESENTATIVE,
  ENTITY_LINK_TYPE_HAS_REPRESENTED_COMPANY
} from '@deecision/dna-interfaces';
import { AugmentedEntity } from '@/main/providers/getter';

function RelationTypeChip(props: { entity: AugmentedEntity }): ReactElement {
  const { t } = useTranslation();

  const getRelationType = useCallback(() => {
    if (props.entity.linkTypes.includes(ENTITY_LINK_TYPE_HAS_REPRESENTED_COMPANY)) return t('common.network.motherCompany');
    if (props.entity.linkTypes.includes(ENTITY_LINK_TYPE_HAS_REPRESENTATIVE)) return t('common.network.childCompany');

    return undefined;
  }, [props.entity.linkTypes]);

  if (!getRelationType()) {
    return <></>;
  }

  return (
    <Stack direction='row' spacing={2} maxWidth={340} flexWrap='wrap' useFlexGap>
      <Chip
        size='small'
        label={getRelationType()}
      />
    </Stack>
  );
}

export default RelationTypeChip;
