import React, { ReactElement } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Chip, useTheme } from '@mui/material';
import { colorTableForLevels } from '@/components/charts/utils';

function EconomicalPotentialInfo(): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack spacing={6}>
      <Stack spacing={2}>
        <Chip
          label={t('common.levels.level_0')}
          sx={{
            bgcolor: colorTableForLevels.level_0,
            '.MuiChip-label': {
              color: theme.palette.primary.contrastText
            }
          }}
          size='small'
        />
        <Typography variant='body1'>
          {t('segmentation.render.segmentationCriterias.informations.person_weasse_economical_potential.level_0')}
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Chip
          label={t('common.levels.level_1')}
          sx={{
            bgcolor: colorTableForLevels.level_1,
            '.MuiChip-label': {
              color: theme.palette.primary.contrastText
            }
          }}
          size='small'
        />
        <Typography variant='body1'>
          {t('segmentation.render.segmentationCriterias.informations.person_weasse_economical_potential.level_1')}
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Chip
          label={t('common.levels.level_2')}
          sx={{
            bgcolor: colorTableForLevels.level_2,
            '.MuiChip-label': {
              color: theme.palette.primary.contrastText
            }
          }}
          size='small'
        />
        <Typography variant='body1'>
          {t('segmentation.render.segmentationCriterias.informations.person_weasse_economical_potential.level_2')}
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Chip
          label={t('common.levels.level_3')}
          sx={{
            bgcolor: colorTableForLevels.level_3,
            '.MuiChip-label': {
              color: theme.palette.primary.contrastText
            }
          }}
          size='small'
        />
        <Typography variant='body1'>
          {t('segmentation.render.segmentationCriterias.informations.person_weasse_economical_potential.level_3')}
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Chip
          label={t('common.levels.level_4')}
          sx={{
            bgcolor: colorTableForLevels.level_4,
            '.MuiChip-label': {
              color: theme.palette.primary.contrastText
            }
          }}
          size='small'
        />
        <Typography variant='body1'>
          {t('segmentation.render.segmentationCriterias.informations.person_weasse_economical_potential.level_4')}
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Chip
          label={t('common.levels.level_5')}
          sx={{
            bgcolor: colorTableForLevels.level_5,
            '.MuiChip-label': {
              color: theme.palette.primary.contrastText
            }
          }}
          size='small'
        />
        <Typography variant='body1'>
          {t('segmentation.render.segmentationCriterias.informations.person_weasse_economical_potential.level_5')}
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Chip
          label={t('common.levels.level_6')}
          sx={{
            bgcolor: colorTableForLevels.level_6,
            '.MuiChip-label': {
              color: theme.palette.primary.contrastText
            }
          }}
          size='small'
        />
        <Typography variant='body1'>
          {t('segmentation.render.segmentationCriterias.informations.person_weasse_economical_potential.level_6')}
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Chip
          label={t('common.levels.level_7')}
          sx={{
            bgcolor: colorTableForLevels.level_7,
            '.MuiChip-label': {
              color: theme.palette.primary.contrastText
            }
          }}
          size='small'
        />
        <Typography variant='body1'>
          {t('segmentation.render.segmentationCriterias.informations.person_weasse_economical_potential.level_7')}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default EconomicalPotentialInfo;
