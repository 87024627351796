import React from 'react';
import {
  BodaccDataBlock,
  CompanyEntity,
  FinancialReportDataBlock,
  BingNewsData,
  GoogleCseDataBlock,
  NewsApiOrgData,
  VeegilenzPersonSummaryV1DataBlockContent,
  zCompanyEntity, zIDataElement
} from '@deecision/dna-interfaces';
import { z } from 'zod';
import IconBuildings from '@/assets/custom/IconBuildings';
import createElementRoutes from '@/router/create';
import { customGetterLoader } from '@/router/loader';
import CompaniesComponent from './index';
import CompanyComponent from './components';
import BaseDnaEntitiesService from '../services/entities';
import DashboardTabsComponents from './components/tabs/dashboard';
import DeveloperTabsComponents from '@/main/modules/entities/common/tabs/developer';
import IaTabsComponents from './components/tabs/ia';
import BodaccTabsComponents, { bodaccDataPaths } from './components/tabs/bodacc';
import { DataBlocksQueryService, DataElementsQueryService } from '@/api/services/query';
import FinancialTabsComponents from './components/tabs/financial';
import dnaConfig from '../../../../config/dna.config.json';
import NewsTabsComponents, { newsDataPaths } from '@/main/modules/entities/common/tabs/news';
import OrigeenTabsComponents from '../common/tabs/origeen';
import NetworkTabsComponents from '@/main/modules/entities/common/tabs/network';
import RelationsTabsComponents from './components/tabs/relations';
import Veegilenz, { veegilenzCompanyPaths } from '../../../containers/veegilenz';
import { tabsFunctions } from './components/tabs/utils/functions';
import { CustomRouteObject } from '@/router/types';
import CompaniesTabsComponents from '@/main/modules/entities/common/tabs/companies';

const tabsCompanies: CustomRouteObject[] = [
  {
    id: dnaConfig.routes.companies.tabs.dashboard.label,
    i18nKey: dnaConfig.routes.companies.tabs.dashboard.i18nKey,
    path: dnaConfig.routes.companies.tabs.dashboard.baseUrl,
    element: <DashboardTabsComponents />
  },
  {
    id: dnaConfig.routes.companies.tabs.network.label,
    i18nKey: dnaConfig.routes.companies.tabs.network.i18nKey,
    path: dnaConfig.routes.companies.tabs.network.baseUrl,
    element: <NetworkTabsComponents<CompanyEntity> />
  },
  {
    id: dnaConfig.routes.companies.tabs.companies.label,
    i18nKey: dnaConfig.routes.companies.tabs.companies.i18nKey,
    path: dnaConfig.routes.companies.tabs.companies.baseUrl,
    element: <CompaniesTabsComponents />
  },
  {
    id: dnaConfig.routes.companies.tabs.relations.label,
    i18nKey: dnaConfig.routes.companies.tabs.relations.i18nKey,
    path: dnaConfig.routes.companies.tabs.relations.baseUrl,
    element: <RelationsTabsComponents />
  },
  {
    id: dnaConfig.routes.companies.tabs.news.label,
    i18nKey: dnaConfig.routes.companies.tabs.news.i18nKey,
    path: dnaConfig.routes.companies.tabs.news.baseUrl,
    element: <NewsTabsComponents />,
    loader: ({ request, params }) => customGetterLoader({
      service: new DataBlocksQueryService<BingNewsData | NewsApiOrgData | GoogleCseDataBlock>({ dataPath: newsDataPaths, entityType: 'deecCompany' }),
      params,
      request
    })
  },
  {
    id: dnaConfig.routes.companies.tabs.veegilenz.label,
    i18nKey: 'Veegilenz',
    path: dnaConfig.routes.companies.tabs.veegilenz.baseUrl,
    element: <Veegilenz entityType='deecCompany' />,
    loader: ({ request, params }) => customGetterLoader({
      service: new DataBlocksQueryService<VeegilenzPersonSummaryV1DataBlockContent>({ dataPath: veegilenzCompanyPaths, entityType: 'deecCompany' }),
      params,
      request
    })
  },
  {
    id: dnaConfig.routes.companies.tabs.financial.label,
    i18nKey: dnaConfig.routes.companies.tabs.financial.i18nKey,
    path: dnaConfig.routes.companies.tabs.financial.baseUrl,
    element: <FinancialTabsComponents />,
    loader: ({ request, params }) => customGetterLoader({
      service: new DataBlocksQueryService<FinancialReportDataBlock>({ dataPath: 'company.financialReportDetails', entityType: 'deecCompany' }),
      params,
      request
    })
  },
  {
    id: dnaConfig.routes.companies.tabs.officialInformations.label,
    i18nKey: dnaConfig.routes.companies.tabs.officialInformations.i18nKey,
    path: dnaConfig.routes.companies.tabs.officialInformations.baseUrl,
    element: <BodaccTabsComponents />,
    loader: ({ request, params }) => customGetterLoader({
      service: new DataBlocksQueryService<BodaccDataBlock>({ dataPath: bodaccDataPaths, entityType: 'deecCompany' }),
      params,
      request
    })
  },
  {
    id: dnaConfig.routes.companies.tabs.ai.label,
    i18nKey: 'AI',
    path: dnaConfig.routes.companies.tabs.ai.baseUrl,
    handle: {
      tabChips: [
        'Beta'
      ]
    },
    element: <IaTabsComponents />
  },
  {
    id: dnaConfig.routes.companies.tabs.origeen.label,
    i18nKey: 'Origeen',
    path: dnaConfig.routes.companies.tabs.origeen.baseUrl,
    element: <OrigeenTabsComponents entityType='deecCompany'/>
  },
  {
    id: dnaConfig.routes.companies.tabs.developer.label,
    i18nKey: dnaConfig.routes.companies.tabs.developer.i18nKey,
    path: dnaConfig.routes.companies.tabs.developer.baseUrl,
    element: <DeveloperTabsComponents<CompanyEntity> zodSchema={zCompanyEntity} />
  }
];

const routesCompanies: CustomRouteObject = createElementRoutes<CompanyEntity>({
  i18nKey: dnaConfig.routes.companies.i18nKey,
  path: dnaConfig.routes.companies.baseUrl,
  icon: IconBuildings,
  component: <CompaniesComponent />,
  idComponent: <CompanyComponent />,
  Service: new BaseDnaEntitiesService<CompanyEntity>({ entityType: 'company' }),
  displayIndex: true,
  tabs: tabsCompanies,
  tabsDisplayService: new DataElementsQueryService({ entityType: 'deecCompany', schema: zIDataElement(z.any()) }),
  tabsFunctions
});

export default routesCompanies;
